export const environment = {
  production: true,
  apiBaseUrl: 'https://p2froots.firmenich.com',
  port: '3000',
  secret_key: 'firmenich',
  snapshotRefreshInterval: 86400000,
  imageCompress:{
    quality: 0.8,
    maxWidth:500,
    maxHeight:500,
    mimeType:"image/webp",
    convertSize:50000
 }
};
